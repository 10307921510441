<template>
  <div id="app" class="nodrag">
    <!-- <div class="splash-screen" v-if="showSplashScreen">
      <SplashScreen />
    </div>
    <div v-else> -->
    <router-view />
    <!-- <MyPage v-show="isShowComponent" @button-close="closeAside"></MyPage> -->
    <!-- <child :label="buttonLabel" @button-clicked="showMypage"></child> -->
    <!-- </div> -->
  </div>
</template>
<script>
// import SplashScreen from '@/views/main/splash.vue';
// import MyPage from "@/views/mypage/mypage-no-pet.vue"
// import Child from "./components/homeFooter.vue";

export default {
  name: "App",
  components: {
    // SplashScreen,
    // MyPage,
    // Child
  },
  data() {
    return {
      isShowComponent: false,
      showSplashScreen: true,
      buttonLabel: "마이",
    };
  },
  methods: {
    showMypage() {
      // isShowComponent 값을 true로 변경하여 MyComponent를 표시합니다.
      this.isShowComponent = !this.isShowComponent;
    },
    closeAside() {
      this.isShowComponent = false;
    },
  },
  created() {
    // 스플래시 화면 보이기
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 3000);
  }
};
</script>

<style>
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-Bold.otf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-ExtraBold.otf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-SemiBold.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-Medium.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-Regular.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-Light.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-ExtraLight.otf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Pretendard";
  src: url("assets/fonts/Pretendard-Thin.otf") format("truetype");
  font-weight: 100;
}

#app {
  font-family: Pretendard-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nodrag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
