import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "LoginIndex",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/",
    name: "Main",
    component: () => import("@/components/Layout/footerLayout.vue"),
    children: [
      {
        path: "/",
        name: "MainIndex",
        component: () => import("@/views/main/index.vue"),
      },
      {
        path: "/mypage/pet-registration",
        name: "PetRegi",
        meta: "",
        component: () => import("@/views/mypage/pet-registration.vue"),
      },
      {
        path: "/mypage/pet-edit/:id",
        name: "PetEdit",
        meta: "",
        component: () => import("@/views/mypage/pet-edit.vue"),
      },
      {
        path: "/mypage/mypage",
        name: "MyPageIndex",
        meta: "",
        component: () => import("@/views/mypage/mypage.vue"),
      },
      {
        path: "/mypage/password_update",
        name: "PassWordUpdate",
        meta: "",
        component: () => import("@/views/mypage/password.vue"),
      },
    ],
  },
  {
    path: "/login/register",
    component: () => import("@/components/Layout/registerLayout.vue"),
    children: [
      {
        path: "/login/register",
        name: "Register",
        component: () => import("@/views/login/register.vue"),
      },
      {
        path: "/login/register3",
        name: "Register3",
        component: () => import("@/views/login/register3.vue"),
      },
      {
        path: "/findID",
        name: "FindID",
        component: () => import("@/views/login/find_id.vue"),
      },
      {
        path: "/findPW",
        name: "FindPW",
        component: () => import("@/views/login/find_pw.vue"),
      },
    ],
  },
  {
    path: "/mypage",
    name: "MyPage",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/mypage/mypage-no-pet",
        name: "MyPageNoPet",
        component: () => import("@/views/mypage/mypage-no-pet.vue"),
      },

      {
        path: "/mypage/faq",
        name: "FAQ",
        meta: {
          title: "Help Center",
          logo: "faq-icon.png",
        },
        component: () => import("@/views/mypage/faq.vue"),
      },
      {
        path: "/mypage/notify-setting",
        name: "NotifySetting",
        meta: {
          title: "Health",
          logo: "health-icon.png",
        },
        component: () => import("@/views/mypage/notify-setting.vue"),
      },
    ],
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "ScheduleIndex",
        meta: {
          title: "Schedule",
          logo: "schedule-icon.png",
        },
        component: () => import("@/views/schedule/index.vue"),
      },
      {
        path: "/schedule/add_schedule",
        name: "AddSchedule",
        meta: {
          title: "Schedule",
          logo: "schedule-icon.png",
        },
        component: () => import("@/views/schedule/add_schedule.vue"),
      },
      {
        path: "/schedule/update_schedule",
        name: "UpdateSchedule",
        meta: {
          title: "Schedule",
          logo: "schedule-icon.png",
        },
        component: () => import("@/views/schedule/update_schedule.vue"),
      },
    ],
  },
  {
    path: "/walk",
    name: "Walk",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "WalkIndex",
        meta: {
          title: "Walk",
          logo: "walk-icon.png",
        },
        component: () => import("@/views/walk/index.vue"),
      },
      {
        path: "/walk/map",
        name: "walkmap",
        meta: {
          title: "Walk",
          logo: "walk-icon.png",
        },
        component: () => import("@/views/walk/walkmap.vue"),
      },
      {
        path: "/walk/hunt",
        name: "huntingmap",
        meta: {
          title: "Hunt",
          logo: "walk-icon.png",
        },
        component: () => import("@/views/walk/hunt.vue"),
      },
    ],
  },
  {
    path: "/health",
    name: "Health",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "HealthIndex",
        meta: {
          title: "Health",
          logo: "health-icon.png",
        },
        component: () => import("@/views/health/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!Vue.$cookies.get("accessToken");
  if (
    to.name !== "LoginIndex" &&
    to.name !== "Register" &&
    to.name !== "Register3" &&
    to.name !== "FindID" &&
    to.name !== "FindPW" &&
    !isAuthenticated
  ) {
    // alert("Please login again.");
    next({ name: "LoginIndex" });
  } else {
    next();
  }
});

export default router;
